import React from "react"
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/global.css"
import Header from "./header.en"
import Footer from "./footer.en"

export default ({ children, pageId }) => (
  <div id="main-wrapper" className='container-fluid' style={{paddingRight: 0, paddingLeft:0}}>
    <Header current={pageId}/>
    {children}
    <Footer/>
  </div>
)
