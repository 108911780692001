import React from "react"
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/global.css"
import Header from "./header"
import Footer from "./footer"

export default ({ children, pageId, onClick }) => (
  <div id="main-wrapper" className='container-fluid' style={{paddingRight: 0, paddingLeft:0}} onClick={onClick}>
    <Header current={pageId}/>
    {children}
    <Footer/>
  </div>
)
