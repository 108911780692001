import React from "react"
import "./header.css"
import HomePageYAMLData from "../../content/home.en.yaml"

export default props => (
  <div className='nav-content container-fluid fixed-top'>
    <nav className="navbar navbar-expand-lg navbar-light  h-100 pt-0 pb-0">
      <img src='/images/header/Logo-eye.png' className="logo"/>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"/>
      </button>
      <div className="collapse navbar-collapse " id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className={"nav-item"}>
            <a className={'nav-link menu '} href="/en" >{HomePageYAMLData.header_title_1}
              <span className="sr-only">(current)</span>
              <div className={props.current === "/en" ? "div_nav_line  nav-active" : "div_nav_line"}/>
            </a>

          </li>
          <li className={"nav-item"}>
            <a className="nav-link menu" href="/en/business">{HomePageYAMLData.header_title_2}
              <div className={props.current === "/en/business" ? "div_nav_line  nav-active" : "div_nav_line"}/>
            </a>
          </li>
          <li className={"nav-item"}>
            <a className="nav-link menu" href="/en/control">{HomePageYAMLData.header_title_3}
              <div className={props.current === "/en/control" ? "div_nav_line  nav-active" : "div_nav_line"}/>
            </a>
          </li>
          <li className={"nav-item"}>
            <a className="nav-link menu" href="/en/partner">{HomePageYAMLData.header_title_4}
              <div className={props.current === "/en/partner" ? "div_nav_line  nav-active" : "div_nav_line"}/>
            </a>
          </li>
          <li className={"nav-item"}>
            <a className="nav-link menu" href="/en/about">{HomePageYAMLData.header_title_5}
              <div className={props.current === "/en/about" ? "div_nav_line  nav-active" : "div_nav_line"}/>
            </a>
          </li>
          <li className='language_div ml-lg-5 ml-0 '>
            <div>
              <a className='language_item'  href="/">中文</a>
              <a className='language_item'  href="/en/">English</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>

)
