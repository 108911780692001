import React from "react"
import './footer.css'
import HomePageYAMLData from "../../content/home.en.yaml"
export default () => (
  <div className="container-fluid px-0" style={{ backgroundColor: "#3E4C5B" }}>
    <div className='container justify-content-center' style={{color: "#ffffff" }}>
      <div className='row' >
        <div className='col-4'>
          <div style={{ marginTop: 73 }}>
            <img src='/images/header/Logo-eye.png' style={{ width: 40.82 }}/>
            <span style={{ fontWeight: "bold", color: "#d9d9d9", fontSize: 18, marginLeft: 11 }}>
              {HomePageYAMLData.footer_left_0}
              {/*CORELAB*/}
            </span>
          </div>
          <div style={{ fontSize: 12, color: "#D9D9D9", marginTop: 5 }}>
            {HomePageYAMLData.footer_left_1} &nbsp; &nbsp;{HomePageYAMLData.footer_left_2} &nbsp; &nbsp;{HomePageYAMLData.footer_left_3}&nbsp; &nbsp;{HomePageYAMLData.footer_left_4}

          </div>
        </div>
        <div className='col-4'>
          <div style={{ marginTop: 62 }}>
            <div style={{ fontSize: 14 }}>
              {/*导航*/}
              {HomePageYAMLData.footer_center_1}
            </div>
            <div style={{ backgroundColor: "#FFFFFF", height: 1, marginTop: 14}} />
            <div className='row mx-0'>
              <div className='col-6 px-0' style={{marginTop: 10}}>

                <a href="/en"><span className='footer-nav-item'> {HomePageYAMLData.footer_center_2}</span></a>
              </div>
              <div className='col-6 px-0' style={{marginTop: 10}}>
                <a href="/en/business"><span className='footer-nav-item'> {HomePageYAMLData.footer_center_3}</span></a>
              </div>
              <div className='col-6 px-0' style={{fontSize: 13, color: '#D9D9D9',lineHeight: '25px',  marginTop: 10}}>
                <a href="/en/control"><span className='footer-nav-item'> {HomePageYAMLData.footer_center_4}</span></a>
              </div>
              <div className='col-6 px-0' style={{fontSize: 13, color: '#D9D9D9',lineHeight: '25px',  marginTop: 10}}>
                <a href="/en/partner"><span className='footer-nav-item'> {HomePageYAMLData.footer_center_5}</span></a>
              </div>
              <div className='col-6 px-0' style={{fontSize: 13, color: '#D9D9D9',lineHeight: '25px',  marginTop: 10}}>
                <a href="/en/about"><span className='footer-nav-item'> {HomePageYAMLData.footer_center_6}</span></a>
              </div>
              {/*<div className='col-auto w-25' style={{fontSize: 13, color: '#D9D9D9',lineHeight: '25px',  marginTop: 10}}>*/}
              {/*  <a href="/about">联系我们</a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div style={{ marginTop: 62 }}>
            <div style={{ fontSize: 14 }}>
              {HomePageYAMLData.footer_right_1}
              {/*联系我们*/}
            </div>
            <div style={{ backgroundColor: "#FFFFFF", height: 1, marginTop: 14,}}/>
            <div className='row mx-0 ' style={{marginTop: 10}}>
              <div className='col-auto px-1' >
                <img src={'/images/footer/mail.svg'}/>
              </div>
              <div className='col-10 px-2' style={{fontSize: 12, color: '#D9D9D9',lineHeight: '25px'}}>
                {HomePageYAMLData.footer_right_2}
                {/*info@imagecorelabcn.com*/}
              </div>
            </div>
            <div className='row mx-0 ' style={{marginTop: 10}}>
              <div className='col-auto px-1' >
                <img src={'/images/footer/phone.svg'}/>
              </div>
              <div className='col-10 px-2' style={{fontSize: 12, color: '#D9D9D9',lineHeight: '25px'}}>
                {HomePageYAMLData.footer_right_3}
                {/*010-63328123*/}
              </div>
            </div>
            <div className='row mx-0 align-items-center' style={{marginTop: 10}}>
              <div className='col-auto px-1' >
                <img src={'/images/footer/map-pin.svg'}/>
              </div>
              <div className='col-10 px-2' style={{fontSize: 12, color: '#D9D9D9',lineHeight: '25px'}}>
                {HomePageYAMLData.footer_right_4}
                {/*北京市西城区马连道路19号十四层*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-center' style={{marginTop: 67}}>
        <div className='footer-nav-item'>
          {HomePageYAMLData.footer_bottom_1}
          {/*Copyright©2020, Image Core Lab. All rights reserved*/}
        </div>
      </div>
      <div className='row justify-content-center' style={{marginTop: 5, paddingBottom: 30}}>
        <div className='footer-nav-item'>
          <a target="_blank" href="http://beian.miit.gov.cn"><span className="footer-nav-item">{HomePageYAMLData.footer_bottom_2}</span></a>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo"><span className="footer-nav-item">{HomePageYAMLData.footer_bottom_3}</span></a>
        </div>
      </div>
    </div>
  </div>
);
